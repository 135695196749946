import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// 查询工艺参数列表
export function listCraft(query: any) {
  return request({
    url: "/factory/craft/list",
    method: "get",
    params: query,
  });
}

// 查询工艺参数详细
export function getCraft(query: any) {
  return request({
    url: "/factory/craft/getInfo",
    method: "get",
    params: query,
  });
}

// 新增工艺参数
export function addCraft(data: any) {
  return request({
    url: "/factory/craft",
    method: "post",
    data: data,
  });
}

// 修改工艺参数
export function updateCraft(data: any) {
  return request({
    url: "/factory/craft",
    method: "put",
    data: data,
  });
}

// 删除工艺参数
export function delCraft(data: any) {
  return request({
    url: "/factory/craft/removes",
    method: "delete",
    data: data,
  });
}
